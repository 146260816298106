/**
 * @name Fonts
 * @description
 */

//*--------------------------------------------------*
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 100;
  src: url('../../assets/fonts/PretendardStd-Thin.otf') format('truetype');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/PretendardStd-Light.otf') format('truetype');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/PretendardStd-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/PretendardStd-Medium.otf') format('truetype');
}
@font-face {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/PretendardStd-SemiBold.otf') format('truetype');
}
//*--------------------------------------------------*
// @font-face {
//   font-family: 'Spoqa Han Sans Neo';
//   font-weight: 500;
//   src: url('../../assets/fonts/SpoqaHanSansNeo-Regular.ttf') format('truetype');
// }
// @font-face {
//   font-family: 'Spoqa Han Sans Neo';
//   font-weight: 700;
//   src: url('../../assets/fonts/SpoqaHanSansNeo-Bold.ttf') format('truetype');
// }

/********************
CSS기준으로 font위치 상대경로

폰트변환참고: https://anyconv.com/ko/ttf-to-eot-byeonhwangi/
@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    src: local('Spoqa Han Sans Neo Bold'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2') format('woff2'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff') format('woff'),
    url('https://cdn.jsdelivr.net/gh/spoqa/spoqa-han-sans@latest/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.ttf') format('truetype');
}
********************/
