/**
 * @name root 글로벌변수
 */
:root {
  /*===================================================================[height] */
  --DOC_HEIGHT: calc(100vh);
  /*===================================================================[color] */
  // placeholder
  --COLOR_INPUT_PLACEHOLDER: #ccc;
  /*===================================================================[color] */
  --box-shadow: 0 0 0 1px rgba(3, 3, 0, 0.9), 0 1px 2px rgba(0, 0, 0, 0.9);
}

/********************************************************
[사용법]
  border-bottom: 1px solid var(--COLOR_GRAY5);
  min-height: var(--DOC_HEIGHT);
  max-height: var(--DOC_HEIGHT);
*********************************************************/
