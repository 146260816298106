/**
 * @name Text
 * @description
 */
//============================================================================ 기본폰트스타일
.essential-text {
  vertical-align: middle;
  font-family: 'Pretendard';
}
//============================================================================ input,textarea
textarea {
  resize: none; // 리사이즈제거
}
//============================================================================ Text
body {
  .H1 {
    font-size: 196px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 294px */
    letter-spacing: -0.4px;
  }
  .H2 {
    font-size: 140px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 210px */
    letter-spacing: -0.4px;
  }
  .H3 {
    font-size: 80px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 120px */
    letter-spacing: -0.4px;
  }
  .H4 {
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 96px */
    letter-spacing: -0.4px;
  }
  .H5 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 160% */
    letter-spacing: -0.375px;
  }
  //==================== Custom
  .Display_SB {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px; /* 127.778% */
    letter-spacing: 0;
  }
  .Display_MB {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 125% */
    letter-spacing: 0;
  }
  .Display_LB {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 125% */
    letter-spacing: 0;
  }
  //====== Headline
  .Headline_SB {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: 0;
  }
  .Headline_MB {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0;
  }
  .Headline_LB {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: 0;
  }
  //====== Title
  .Title_SB {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0;
  }
  .Title_MB {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0;
  }
  .Title_LB {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 144.444% */
    letter-spacing: 0;
  }
  //====== Body
  .Body_SM {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.2px;
  }
  .Body_MM {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
  }
  .Body_MM_underline {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
    text-decoration-line: underline;
  }
  .Body_LM {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.2px;
  }
  .Body_SR {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.2px;
  }
  .Body_MR {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
  }
  .Body_MR_underline {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
    text-decoration-line: underline;
  }
  .Body_LR {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: -0.2px;
  }
  //====== Caption
  .Caption_SB {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 180% */
    letter-spacing: -0.2px;
  }
  .Caption_MB {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.2px;
  }
  .Caption_LB {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
  }
  .Caption_SR {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 180% */
    letter-spacing: -0.2px;
  }
  .Caption_MR {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.2px;
  }
  .Caption_LR {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.2px;
  }
  //====== Button
  .Btn_S {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 166.667% */
  }
  .Btn_M {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  .Btn_L {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
}
